import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import 'typeface-barlow';
import '../sass/global/styles.scss';

import { Header, Footer } from './Common';

const TemplateWrapper = ({ location, children, className }) => (
  <div id="app" className={className}>
    <Header location={location} />
    <main className="page-content">{children}</main>
    <Footer location={location} />
  </div>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={data => <TemplateWrapper data={data} {...props} />}
  />
)
