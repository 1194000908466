import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Navigation from './Navigation';
import { Logo } from './Logo';
import './Header.scss';

const isClient = typeof window !== 'undefined';

class HeaderTemplate extends Component {
  state = {
    scrolled: false,
    upScroll: false,
    lastScrollPos: 0,
    navOpen: false
  };

  componentDidMount() {
    if (isClient) window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    if (isClient) window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    if (event) event.preventDefault();
    const { lastScrollPos } = this.state;
    const currScrollPos = window.scrollY;
    if (currScrollPos > lastScrollPos) {
      // scrolling down
      if (currScrollPos > 0) {
        this.setState({
          scrolled: true,
          upScroll: false
        });
      }
    } else {
      // scrolling up
      // eslint-disable-next-line
      if (currScrollPos < 80) {
        this.setState({
          scrolled: false,
          upScroll: false
        });
      } else {
        this.setState({ upScroll: true });
      }
    }
    this.setState({
      lastScrollPos: currScrollPos
    });
  }

  // eslint-disable-next-line
  toggleNavigation = event => {
    if (event) event.preventDefault();
    const nav = document.querySelector('.right-navigation');
    const { navOpen } = this.state;
    if (!navOpen) {
      disableBodyScroll(nav);
    } else {
      enableBodyScroll(nav);
    }
    this.setState({ navOpen: !navOpen });
  }

  closeNavigation = event => {
    if (event) event.preventDefault();
    clearAllBodyScrollLocks();
    this.setState({ navOpen: false });
  }

  render() {
    const {
      data,
      location = {},
    } = this.props;
    const { pathname = null } = location;
    const {
      site,
      leftNavigation,
      rightNavigation,
    } = data;
    const { title: siteName, wordpressUrl } = site.siteMetadata;
    const {
      scrolled,
      upScroll,
      navOpen,
    } = this.state;
    return (
      <header
        id="banner"
        role="banner"
        className={`${scrolled ? 'scrolled' : ''} ${upScroll ? 'up-scroll' : ''}`}
      >
        <div className="wrap">
          <Navigation
            wordpressUrl={wordpressUrl}
            items={leftNavigation.items}
            currentPath={pathname}
            className="left-navigation"
            closeNavigation={event => this.closeNavigation(event)}
          />
          <Logo
            siteName={siteName}
            url="/"
            closeNavigation={event => this.closeNavigation(event)}
          />
          <Navigation
            wordpressUrl={wordpressUrl}
            items={rightNavigation.items}
            currentPath={pathname}
            className={`right-navigation ${navOpen ? 'nav-open' : ''}`}
            closeNavigation={event => this.closeNavigation(event)}
          />
          <div className="toggle-nav-container">
            {/* eslint-disable-next-line */}
            <span
              className="toggle-nav"
              onClick={event => this.toggleNavigation(event)}
            />
          </div>
        </div>
      </header>
      );
    }
  }

  export const Header = (props) => (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title,
              wordpressUrl,
            }
          }
          leftNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "left-navigation" }) {
            items {
              wordpress_id
              order
              wordpress_parent
              title
              url
              attr
              target
              classes
              xfn
              description
              object_id
              object
              object_slug
              type
              type_label
              children: wordpress_children {
                wordpress_id
                order
                wordpress_parent
                title
                url
                attr
                target
                classes
                xfn
                description
                object_id
                object
                object_slug
                type
                type_label
              },
            }
          }
          rightNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "right-navigation" }) {
            items {
              wordpress_id
              order
              wordpress_parent
              title
              url
              attr
              target
              classes
              xfn
              description
              object_id
              object
              object_slug
              type
              type_label
              children: wordpress_children {
                wordpress_id
                order
                wordpress_parent
                title
                url
                attr
                target
                classes
                xfn
                description
                object_id
                object
                object_slug
                type
                type_label
              },
            }
          }
        }
      `}
      render={data => <HeaderTemplate data={data} {...props} />}
    />
  )
