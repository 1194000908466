import React, { Component } from 'react';
import { Link } from 'gatsby';

const NavigationLink = (props) => {
  const {
    item,
    closeNavigation,
    currentPath,
    wordpressUrl
  } = props;
  const {
    children,
    classes,
    url,
    title,
  } = item;
  const isParentLink = Boolean(children && children.length > 0);
  const pageUrl = (classes.indexOf('category') !== -1) ? url.replace(/\/blog\/category\/posts/, '/category') : url;
  // External Link Case
  if (classes && classes.indexOf('external') !== -1) {
    return (
      <a
        href={pageUrl}
        target="_blank"
        rel="noopener noreferrer"
        dangerouslySetInnerHTML={{__html: title}}
        onClick={() => closeNavigation()}
      />
    );
  }
  // Internal Link Case
  const splat = pageUrl.replace(wordpressUrl, '');
  const isActive = (currentPath === splat) || (isParentLink && children.find(child => child.url.replace(wordpressUrl, '') === currentPath));
  return (
    <Link
      to={splat}
      className={isActive ? 'active' : ''}
      aria-current="true"
      dangerouslySetInnerHTML={{__html: title}}
      onClick={() => closeNavigation()}
    />
  );
};

const SubNavigation = (props) => {
  const { items, closeNavigation, currentPath, wordpressUrl } = props;
  return (
    <ul className="sub">
      {items.map(child => (
        <li key={child.order} className={`child ${child.classes}`}>
          <NavigationLink
            item={child}
            wordpressUrl={wordpressUrl}
            closeNavigation={closeNavigation}
            currentPath={currentPath}
          />
        </li>
      ))}
    </ul>
  );
};

export default class Navigation extends Component {
  state = {
    subNavIsOpen: false,
    subNavIndex: null
  };

  toggleSubNavigation = (event, index) => {
    const {
      subNavIsOpen,
      subNavIndex: currentSubNavIndex,
    } = this.state;
    this.setState({
      subNavIsOpen: currentSubNavIndex === index ? !subNavIsOpen : true,
      subNavIndex: index
    });
  }

  render() {
    const {
      items,
      className,
      closeNavigation,
      currentPath,
      wordpressUrl,
    } = this.props;
    const {
      subNavIndex,
      subNavIsOpen,
    } = this.state;
    return (
      <nav
        className={`navigation-menu${className ? ` ${className}` : ''}`}
        role="navigation"
      >
        <ul className="top">
          {items.map((navItem, index) => {
            const {
              order,
              children,
              classes,
            } = navItem;
            const isParent = (children && children.length > 0);
            return (
              <li
                key={order}
                className={`${isParent ? 'parent' : ''} ${((subNavIndex === index) && subNavIsOpen) ? 'sub-nav-open' : ''} ${classes}`}
              >
                <NavigationLink
                  wordpressUrl={wordpressUrl}
                  item={navItem}
                  closeNavigation={closeNavigation}
                  currentPath={currentPath}
                />
                {children && children.length > 0 ? (
                  <SubNavigation
                    wordpressUrl={wordpressUrl}
                    items={children}
                    closeNavigation={closeNavigation}
                    currentPath={currentPath}
                  />
                ) : null}
                {isParent &&
                  /* eslint-disable-next-line */
                  <span
                    className="sub-nav-toggle"
                    onClick={(event) => this.toggleSubNavigation(event, index)}
                  />
                }
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}
